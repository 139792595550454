import config from '@/config.js'
import { router } from '@/main.js'

const auth = {
  async authorizeUser(email, password) {
    const headers = {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    }

    const response = await fetch(config.BE_URL + '/v1/auth/token/', {
      method: 'POST',
      headers,
      body: JSON.stringify({
        email,
        password,
      }),
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      return error
    })

    let data
    if (response.ok) {
      data = await response.json()
      // const token = await VueJwtDecode.decode(data.token);
      console.log(data)

      this.saveUserDataToLS(email, data)
    }

    return {
      ok: response.ok,
      status: response.status,
      data: data,
    }
  },

  saveUserDataToLS(email, data) {
    localStorage.setItem('email', email)
    localStorage.setItem('token', data.access)
  },

  removeUserDataFromLS() {
    localStorage.removeItem('email')
    localStorage.removeItem('token')
  },

  logOut() {
    this.removeUserDataFromLS()
    router.push('/merchantadminpanel-login')
  },
}

export default auth

const config = {
  BE_URL: 'https://api.runningtheroad.ca',

  get getLocation() {
    return this.location ?? window.location.href
  },

  get isOnLocalhost() {
    return this.getLocation.includes('localhost')
  },
}

if (config.isOnLocalhost) config.BE_URL = 'http://localhost:8000'

export default config
